// Other
@mixin margin-fix {
	> *:first-child {
		margin-top:0;
	}
	> *:last-child {
		margin-bottom:0;
	}
}

//Images
@mixin img-center {
	display:block;
	margin:0  auto;
}