body {
  //@include font-feature-settings("kern","liga","frac", "pnum");
  -webkit-font-smoothing: antialiased;
  background-color: $white;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: 1em;
  line-height: $base-line-height;
  @include mq(mediumv) {
    font-size:.875em;
  }
  @include mq(smallv) {
    font-size:.75em;
  }
}

//Base Fonts
@mixin base-font-size {
  font-size: em(16);
  @include mq(medium) {
    font-size: em(18);
  }
  @include mq(large) {
    font-size: em(18);
  }
}

//Medium Fonts
@mixin medium-font-size {
  font-size: em(16);
  @include mq(medium) {
    font-size: em(18);
  }
  @include mq(large) {
    font-size: $medium-font-size;
  }
}

//Large Fonts
@mixin large-font-size {
  font-size: $medium-font-size*.75;
  @include mq(medium) {
    font-size: $medium-font-size*.9;
  }
  @include mq(large) {
    font-size: $large-font-size;
  }
}

//xLarge Fonts
@mixin xlarge-font-size {
  font-size: $xlarge-font-size*.75;
  @include mq(medium) {
    font-size: $xlarge-font-size*.9;
  }
  @include mq(large) {
    font-size: $xlarge-font-size;
  }
}

//Display Fonts
@mixin display-font-size {
  font-size: $display-font-size*.75;
  @include mq(medium) {
    font-size: $display-font-size*.9;
  }
  @include mq(large) {
    font-size: $display-font-size;
  }
}

.display-font-size {
  @include display-font-size;
}

.xlarge-font-size {
  @include xlarge-font-size;
}

.large-font-size {
  @include large-font-size;
}

.medium-font-size {
  @include medium-font-size;
  margin-bottom: $baseline*4;
  margin-top: $baseline*3.5;
}

.base-font-size {
  @include base-font-size;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $header-font-family;
  line-height: $header-line-height;
  margin: 0;
}

h1,
.h1 {
  @include display-font-size;
  margin-top: 0;
  margin-bottom: 0px;
  color: $base-color;

}

h2,
.h2 {
  font-size: 2em;
  margin-top: 24px;
  margin-bottom: 16px;
  color: $white;
}

h3,
.h3 {
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: $dark-gray;
  font-weight: bold;
  text-align: center;

  @include mq(medium) {
    font-size: 2em;
  }

  @include mq(large) {
    font-size: 3em;
  }
}

h4,
.h4 {
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: $white;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;

  @include mq(medium) {
    text-align: left;
    font-size: 2em;
  }

  @include mq(large) {
    font-size: 3em;
  }
}

h5,
.h5 {
  @include base-font-size;
  margin-top: 16px;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: $medium-gray;
  font-weight:bold;
}

h6,
.h6 {
  @include base-font-size;
  margin-top: 16px;
  margin-bottom: 16px;
}

p {
  margin: 16px 0;
  font-size:$base-font-size;
}

.emph {
  font-size: $medium-font-size;
  font-weight: normal;
  @include mq(medium) {
    font-size: $medium-font-size*1.15;
  }
}

a {
  @include transition(color 0.1s linear);
  color: $base-link-color;
  text-decoration: underline;
  &:hover {
    color: $hover-link-color;
  }
  &:visited,
  &:active,
  &:focus {
    /*color: $hover-link-color;*/
    outline: none;
  }
  &:focus:not(:hover):not(:active) {
    outline: 2px dashed #006129;
    outline-offset: -1px;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

blockquote {
  padding: 0;
  margin: 0;
}

cite {
  color: lighten($base-font-color, 25);
  font-style: italic;
  &:before {
    content: "\2014 \00A0";
  }
}
