$btn-color:$base-accent-color;
$btn-alt-color:$base-color;
%button {
  position: relative;
  -webkit-font-smoothing: antialiased;
  border-radius: $base-border-radius;
  color: $black;
  display: inline-block;
  border-radius: 6px;
  line-height: 1;
  padding: 0.75em 1.3em;
  text-transform: capitalize;
  text-decoration: none;
  @include transition(background 0.1s linear);
  &:hover {
    color: $white;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:after {
    content: '';
  }
}

.btn {
  @extend %button;
  background-color: $yellow;
  border: 1px solid $yellow;
  &:hover {
    background-color: $black;
    border-color: $black
  }
}

.btn-alt {
  @extend %button;
  background-color: $white;
  border: 1px solid;
  &:hover {
    background-color: $black;
  }
}

.btn-color {
  @extend %button;
  background-color: transparent;
  border:1px solid $white;
  &:hover {
    background-color: white;
    color: $black;
  }
  &:after {
    background-color: darken($btn-alt-color, 10%);
  }
}

button,
input[type="submit"] {
  @extend .btn;
  @include appearance(none);
  border: none;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

//Icon Buttons
%btn-ico {
  @extend %button;
  padding-right: 4em;
  &:after {
    content: '';
    display: block;
    right: 0;
    top: 0;
    bottom: 0;
    width: 3em;
    position: absolute;
    background-position: center center;
    background-size: 1.5em;
    background-repeat: no-repeat;
    @include transition(background 0.1s linear);
  }
  &:hover {
    &:after {
      background-size: 2em;
    }
  }
}

.btn-download {
  @extend %btn-ico;
  @extend .btn;
  &:after {
    // background-image: url(img/ico-download.svg);
  }
}

.btn-alt-download {
  @extend %btn-ico;
  @extend .btn-alt;
  &:after {
    // background-image: url(img/ico-download.svg);
  }
}

.btn-arrow {
  @extend %btn-ico;
  @extend .btn;
  &:after {
    // background-image: url(img/ico-white-arrow-right.svg);
  }
}

.btn-alt-arrow {
  @extend %btn-ico;
  @extend .btn-alt;
  &:after {
    // background-image: url(img/ico-white-arrow-right.svg);
  }
}
